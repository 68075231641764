<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add_sentenza">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Aggiungi sentenza</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="card-body p-6">
              <div
                class="alert alert-danger"
                role="alert"
                v-show="alertCampiObb"
              >
                Compila i campi obbligatori
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row pb-4">
                    <div class="col-sm-3 filter">
                      <label class="fw-bold text-gray-600"
                        >Numero decisione</label
                      >
                      <Field
                        type="text"
                        class="form-control"
                        name="numero_Decisione"
                        v-model="numero_Decisione"
                      />
                    </div>
                    <div class="col-sm-3 filter">
                      <label class="fw-bold text-gray-600"
                        >Data decisione*</label
                      >
                      <Datepicker
                        v-model="data_Sentenza"
                        @update:modelValue="
                          $event
                            ? (data_Sentenza = fromUTCtoISODate($event))
                            : ''
                        "
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                      />
                      <Field
                        name="data_Sentenza"
                        type="hidden"
                        v-model="data_Sentenza"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Sentenza"
                      />
                    </div>
                    <div class="col-sm-6 filter">
                      <label class="fw-bold text-gray-600"
                        >Organo emittente*</label
                      >
                      <SelectInput
                        :value="id_Organo"
                        :options="sanzioni_organi"
                        name="id_Organo"
                        placeholder="-"
                        @changeSelect="setOrganoSanzione($event)"
                      />
                    </div>
                  </div>
                  <div class="row pb-4" v-show="!primogrado">
                    <div class="col-sm-6 filter">
                      <label class="fw-bold text-gray-600">Causa*</label>
                      <SelectInput
                        :value="id_Causa"
                        :options="sanzioni_cause_estintive"
                        name="id_Causa"
                        placeholder="-"
                        @changeSelect="setCausa($event)"
                      />
                      <Field
                        name="primogrado"
                        :value="primogrado"
                        type="hidden"
                      ></Field>
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-sm-12">
                      <label class="fw-bold text-gray-600">Note</label>
                      <Field
                        v-model="note"
                        type="text"
                        as="textarea"
                        class="form-control"
                        name="note"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalAddSent"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import { fromUTCtoISODate } from "@/composables/formatDate";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { addSentenza } from "@/requests/sanzioniDisciplinariRequests";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "add-sentenza-modal",
  components: { Loading, Form, SelectInput, Datepicker, Field, ErrorMessage },
  emits: ["viewProcedimento"],
  props: {
    id_procedimento_prop: {
      type: Number,
      required: true,
    },
    primogrado: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const sanzioni_organi = computed(() =>
      store.getters.getStateFromName("sanzioni_organi")
    );
    const sanzioni_cause_estintive = computed(() =>
      store.getters.getStateFromName("sanzioni_cause_estintive")
    );
    const keys = ref("sce");
    if (!sanzioni_cause_estintive.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }
    if (!sanzioni_organi.value) {
      store.dispatch("setStoreData", {
        apiLink: globalApi.LOOKUP_SANZIONI_ORGANI,
      });
    }

    const numero_Decisione = ref(null);
    const data_Sentenza = ref(fromUTCtoISODate(new Date()));
    const id_Organo = ref(null);
    const note = ref(null);
    const id_Causa = ref(null);

    const setOrganoSanzione = (event) => {
      id_Organo.value = event;
    };

    const setCausa = (event) => {
      id_Causa.value = event;
    };

    const alertCampiObb = ref(false);

    const id_Procedimento = ref(props.id_procedimento_prop);

    const schema = yup.object().shape({
      id_Organo: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      id_Causa: yup.number().when("primogrado", {
        is: (primogrado) => primogrado == false,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("Obbligatorio")
          .nullable(),
        otherwise: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      data_Sentenza: yup.string().required("Obbligatorio").nullable(),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      alertCampiObb.value = false;
      const responseAdd = ref({});
      let messaggioResponse;
      responseAdd.value = addSentenza(values, id_Procedimento.value);
      messaggioResponse = "La sentenza è stata aggiunta con successo";
      responseAdd.value.then((value) => {
        if (value.status == 200) {
          Swal.fire({
            html: messaggioResponse,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            },
          });
          document.getElementById("closeModalAddSent").click();
          emit("viewProcedimento", id_Procedimento.value);
          resetField();
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      numero_Decisione.value = false;
      data_Sentenza.value = fromUTCtoISODate(new Date());
      id_Organo.value = false;
      note.value = false;
      id_Causa.value = false;
    };

    return {
      id_Procedimento,
      sanzioni_organi,
      sanzioni_cause_estintive,
      numero_Decisione,
      data_Sentenza,
      id_Organo,
      note,
      id_Causa,
      setOrganoSanzione,
      setCausa,
      alertCampiObb,
      schema,
      onInvalidSubmit,
      onSubmit,
      resetField,
      fromUTCtoISODate,
      format,
      disableButton,
      isLoading,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>

<template>
  <div class="text-end">
    <div>
      <router-link
        :to="`/sanzioni`"
        class="btn text-hover-primary text-gray-800 fs-6 text-end p-0 m-0 pb-5 pe-4 mb-2"
      >
        <i class="bi bi-arrow-left text-gray-800 fs-6"></i>
        Lista sanzioni
      </router-link>
    </div>
  </div>
  <div class="card mb-7">
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div v-if="isLoaded" :key="isLoaded">
      <div class="">
        <div class="p-6">
          <div class="row">
            <div class="col-sm-3 filter">
              <label class="fw-bold text-gray-600">Stagione</label>
              <select class="form-select" aria-label="" disabled>
                <option selected>
                  {{ jsonViewEditProcedimento.stagione }}
                </option>
              </select>
            </div>
            <div class="col-sm-3 filter">
              <label class="fw-bold text-gray-600">Cod. procedimento</label>
              <input
                type="text"
                disabled
                class="form-control"
                aria-label=""
                :value="jsonViewEditProcedimento.num_proc"
              />
            </div>
            <div class="text-end my-4 col" v-if="!readOnly">
              <!-- <button
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_edit_procedimento"
                type="button"
                class="badge bg-light-warning text-warning rounded fs-6 me-6"
              >
                Modifica procedimento
              </button> -->
              <button
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_add_sentenza"
                type="button"
                class="badge bg-light-success rounded text-success fs-6"
              >
                + Sentenza
              </button>
            </div>
            <!--  <EditProcedimentoModal
              :cod_procedimento_prop="jsonViewEditProcedimento.num_proc"
              :id_procedimento_prop="id_procedimento"
              :stagione_prop="jsonViewEditProcedimento.stagione"
            /> -->
            <AddSentenzaModal
              :id_procedimento_prop="id_procedimento"
              @viewProcedimento="viewEditProcedimento"
              :primogrado="primogrado"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="px-6 mb-5">
          <div
            v-for="(sentenza, index) in jsonViewEditProcedimento.sentenze"
            :key="sentenza.id_sentenza"
          >
            <div class="accordion mb-6" :id="`accordionProcedimento`">
              <div class="accordion-item">
                <div
                  class="accordion-header"
                  id="panelsDecisione-intestazione_1"
                >
                  <button
                    class="accordion-button m-0 p-3"
                    style="background-color: #0084f408"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#panelsDecisione-dettagli_${sentenza.id_sentenza}`"
                    aria-expanded="true"
                    :aria-controls="`#panelsDecisione-dettagli_${sentenza.id_sentenza}`"
                  >
                    Sentenza {{ index + 1 }}° grado:
                    <h6 class="pt-2 ps-2">
                      {{ sentenza.id_sentenza }}
                    </h6>
                  </button>
                </div>
                <div
                  :id="`panelsDecisione-dettagli_${sentenza.id_sentenza}`"
                  class="accordion-collapse collapse show"
                  aria-labelledby="panelsDecisione-intestazione_1"
                >
                  <div
                    class="accordion-body"
                    style="border-bottom: 1px solid #f0f2f5"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row pb-4">
                          <div class="col-sm-3 filter">
                            <label class="fw-bold text-gray-600"
                              >Numero decisione</label
                            >
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              aria-label=""
                              :value="sentenza.numero_decisione"
                            />
                          </div>
                          <div class="col-sm-3 filter">
                            <label class="fw-bold text-gray-600"
                              >Data decisione</label
                            >
                            <Datepicker
                              v-model="sentenza.data_sentenza"
                              :format="format"
                              :monthChangeOnScroll="false"
                              :enableTimePicker="false"
                              inputClassName="h-28"
                              autoApply
                              disabled
                            />
                          </div>
                          <div class="col-sm-6 filter">
                            <label class="fw-bold text-gray-600"
                              >Organo emittente</label
                            >
                            <select class="form-select" aria-label="" disabled>
                              <option selected>
                                {{ sentenza.organo }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row pb-4" v-show="sentenza.id_causa > 0">
                          <div class="col-md-4">
                            <label class="fw-bold text-gray-600">Causa</label>
                            <SelectInput
                              :value="sentenza.id_causa"
                              :options="sanzioni_cause_estintive"
                              placeholder="-"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row pb-4">
                          <div class="col-sm-12">
                            <label class="fw-bold text-gray-600">Note</label>
                            <textarea
                              v-model="sentenza.note"
                              type="text"
                              class="form-control"
                              placeholder=""
                              aria-label=""
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div class="text-end m-4">
                        <button
                          v-if="!readOnly"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_add_sanzione"
                          type="button"
                          class="badge bg-light-success text-success rounded fs-6 me-6"
                          @click="sentenzaSelectedId = sentenza.id_sentenza"
                        >
                          + Sanzione
                        </button>
                        <button
                          v-if="isEnabled('fnSanzioniEditProcedimento')"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_edit_sentenza"
                          type="button"
                          class="badge bg-light-warning text-warning rounded fs-6 me-6"
                          @click="
                            sentenzaSelected = sentenza;
                            sentenzaSelectedId = sentenza.id_sentenza;
                          "
                        >
                          Modifica sentenza
                        </button>
                        <button
                          v-if="
                            jsonViewEditProcedimento.sentenze.length ==
                              index + 1 &&
                            isEnabled('fnSanzioniDeleteTutteSanzioni')
                          "
                          type="submit"
                          class="badge bg-light-danger text-danger rounded fs-6 me-6"
                          @click="delSentenza(sentenza.id_sentenza)"
                        >
                          Elimina
                        </button>
                      </div>
                    </div>
                    <EditSentenzaModal
                      :id_procedimento="id_procedimento"
                      :id_sentenza="sentenzaSelectedId"
                      :sentenza="sentenzaSelected"
                      @viewProcedimento="viewEditProcedimento"
                    />
                    <AddSanzioneModal
                      :id_procedimento="id_procedimento"
                      :id_sentenza="sentenzaSelectedId"
                      :stagione="jsonViewEditProcedimento.stagione"
                      @viewProcedimento="viewEditProcedimento"
                    />
                  </div>
                </div>
                <div class="accordion-item" v-show="sentenza.sanzioni">
                  <div
                    class="accordion-header"
                    id="panelsSanzione-intestazione_1"
                  >
                    <button
                      class="accordion-button m-0 p-3"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="`#panelsSanzione-dettagli_${sentenza.id_sentenza}`"
                      aria-expanded="true"
                      :aria-controls="`#panelsSanzione-dettagli_${sentenza.id_sentenza}`"
                    >
                      Sanzioni:
                      <h6
                        class="pt-2 ps-2"
                        v-for="(sanzione, index) in sentenza.sanzioni"
                        :key="index"
                      >
                        {{ sanzione.id_sanzione }}
                        <span v-show="sentenza.sanzioni.length != index + 1">
                          -
                        </span>
                      </h6>
                    </button>
                  </div>
                  <div
                    :id="`panelsSanzione-dettagli_${sentenza.id_sentenza}`"
                    class="accordion-collapse collapse show"
                    aria-labelledby="panelsSanzione-intestazione_1"
                  >
                    <div
                      class="accordion-body"
                      style="background-color: #0084f408"
                    >
                      <div
                        class="sanzioni-list px-6 pb-6"
                        v-for="(sanzione, index) in sentenza.sanzioni"
                        :key="index"
                      >
                        <div class="row">
                          <div class="col-3 ms-4 mt-4">
                            <div>
                              Id: <b> {{ sanzione.id_sanzione }} </b>
                            </div>
                          </div>
                          <div class="text-end m-4 col">
                            <button
                              v-if="isEnabled('fnSanzioniEditProcedimento')"
                              type="button"
                              class="badge bg-light-warning text-warning roundedfs-6 me-6"
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_edit_sanzione"
                              @click="
                                sanzioneSelected = sanzione;
                                sanzioneSelectedId = sanzione.id_sanzione;
                                getSanzioni(sanzione.id_sanzione);
                              "
                            >
                              Modifica Sanzione
                            </button>
                            <button
                              v-if="
                                isEnabled('fnSanzioniDeleteSingolaSanzione')
                              "
                              type="submit"
                              class="badge bg-light-danger text-danger rounded fs-6 me-2"
                              @click="delSanzione(sanzione.id_sanzione)"
                            >
                              Elimina sanzione
                            </button>
                          </div>
                        </div>
                        <EditSanzioneModal
                          :sanzione="sanzioneSelected"
                          :id_procedimento="id_procedimento"
                          :id_sanzione="sanzioneSelectedId"
                          :sanzioni_stato_limited="sanzioni_stato_limited"
                          @viewProcedimento="viewEditProcedimento"
                        />
                        <div class="sanzioni-item p-6">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row pb-4">
                                <div class="col-md-4">
                                  <label class="fw-bold text-gray-600"
                                    >Stato sanzione</label
                                  >
                                  <select
                                    class="form-select"
                                    aria-label=""
                                    disabled
                                  >
                                    <option selected>
                                      {{ sanzione.stato_sanzione }}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-md-4">
                                  <label class="fw-bold text-gray-600"
                                    >Patteggiamento</label
                                  >
                                  <select
                                    class="form-select"
                                    aria-label=""
                                    disabled
                                  >
                                    <option selected>
                                      {{ sanzione.patteggiamento }}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-md-4">
                                  <label class="fw-bold text-gray-600"
                                    >Sanzione</label
                                  >
                                  <SelectInput
                                    :value="sanzione.id_tipo_sanzione"
                                    :options="sanzioni_tipo"
                                    placeholder="-"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="row pb-4">
                                <div class="col-sm-6 filter">
                                  <label class="fw-bold text-gray-600"
                                    >Società</label
                                  >
                                  <div class="dp__input_wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder=""
                                      aria-label=""
                                      disabled
                                      :value="sanzione.societa"
                                    />
                                  </div>
                                </div>
                                <div class="col-sm-6 filter">
                                  <label class="fw-bold text-gray-600"
                                    >Tesserato</label
                                  >
                                  <div class="dp__input_wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder=""
                                      aria-label=""
                                      disabled
                                      :value="
                                        sanzione.nominativo == ',  '
                                          ? null
                                          : sanzione.nominativo
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="row pb-4"
                                v-show="sanzione.id_tipo_sanzione == 15"
                              >
                                <div class="col-sm-3 filter">
                                  <label class="fw-bold text-gray-600"
                                    >Ammenda</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    aria-label=""
                                    disabled
                                    :value="sanzione.ammenda"
                                  />
                                </div>
                                <div class="col-sm-3 filter">
                                  <label class="fw-bold text-gray-600"
                                    >Data decorrenza</label
                                  >
                                  <Datepicker
                                    v-model="sanzione.data_decorrenza"
                                    placeholder=""
                                    :format="format"
                                    :monthChangeOnScroll="false"
                                    :enableTimePicker="false"
                                    inputClassName="h-28"
                                    autoApply
                                    disabled
                                  />
                                </div>
                              </div>
                              <div
                                class="row pb-4"
                                v-show="
                                  sanzione.id_tipo_sanzione == 6 ||
                                  sanzione.id_tipo_sanzione == 2 ||
                                  sanzione.id_tipo_sanzione == 3 ||
                                  sanzione.id_tipo_sanzione == 4 ||
                                  sanzione.id_tipo_sanzione == 1 ||
                                  sanzione.id_tipo_sanzione == 14 ||
                                  sanzione.id_tipo_sanzione == 12
                                "
                              >
                                <div class="col-md-4">
                                  <label class="fw-bold text-gray-600"
                                    >Giorni</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    aria-label=""
                                    disabled
                                    :value="
                                      sanzione.num_giorni == 0
                                        ? ''
                                        : sanzione.num_giorni
                                    "
                                  />
                                </div>
                                <div class="col-md-4">
                                  <label class="fw-bold text-gray-600"
                                    >Data Inizio</label
                                  >
                                  <Datepicker
                                    v-model="sanzione.data_da"
                                    placeholder=""
                                    :format="format"
                                    :monthChangeOnScroll="false"
                                    :enableTimePicker="false"
                                    inputClassName="h-28"
                                    autoApply
                                    disabled
                                  />
                                </div>
                                <div class="col-md-4">
                                  <label class="fw-bold text-gray-600"
                                    >Data Fine</label
                                  >
                                  <Datepicker
                                    v-model="sanzione.data_a"
                                    placeholder=""
                                    :format="format"
                                    :monthChangeOnScroll="false"
                                    :enableTimePicker="false"
                                    inputClassName="h-28"
                                    autoApply
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr v-show="sentenza.sanzioni.length != index + 1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!isLoaded">
    <div class="mx-auto my-auto text-center my-5">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  getCurrentInstance,
  computed,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { format } from "@/composables/formatDate";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import isEnabled from "@/composables/isEnabled.js";

//import EditProcedimentoModal from "@/components/components-fit/sanzioni-disciplinari/EditProcedimentoModal.vue";
import AddSentenzaModal from "@/components/components-fit/sanzioni-disciplinari/AddSentenzaModal.vue";
import EditSentenzaModal from "@/components/components-fit/sanzioni-disciplinari/EditSentenzaModal.vue";
import AddSanzioneModal from "@/components/components-fit/sanzioni-disciplinari/AddSanzioneModal.vue";
import EditSanzioneModal from "@/components/components-fit/sanzioni-disciplinari/EditSanzioneModal.vue";

import {
  detailProcedimento,
  deleteSanzione,
  deleteSentenza,
} from "@/requests/sanzioniDisciplinariRequests";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "ViewProcedimento",
  components: {
    Datepicker,
    //EditProcedimentoModal,
    AddSentenzaModal,
    EditSentenzaModal,
    AddSanzioneModal,
    EditSanzioneModal,
    SelectInput,
    Loading,
  },

  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Dettaglio procedimento", []);
    });

    const route = useRoute();
    const id_procedimento = route.params.id;
    const jsonViewEditProcedimento = ref(null);
    const isLoaded = ref(false);

    const sentenzaSelected = ref({});
    const sanzioneSelected = ref({});
    const sentenzaSelectedId = ref(null);

    const primogrado = ref(null);

    const viewEditProcedimento = (id) => {
      isLoaded.value = false;
      detailProcedimento(id).then((res) => {
        isLoaded.value = true;
        jsonViewEditProcedimento.value = res.results[0];
        primogrado.value = jsonViewEditProcedimento.value.sentenze
          ? false
          : true;
      });
    };
    viewEditProcedimento(id_procedimento);

    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const sanzioni_tipo = computed(() =>
      store.getters.getStateFromName("sanzioni_tipo")
    );
    const sanzioni_cause_estintive = computed(() =>
      store.getters.getStateFromName("sanzioni_cause_estintive")
    );
    const keys = ref("stip|sce");
    if (!sanzioni_tipo.value || !sanzioni_cause_estintive.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const sanzioni_stato_limited = computed(() =>
      store.getters.getStateFromName("sanzioni_stato_limited")
    );

    const getSanzioni = (id) => {
      store.dispatch("setStoreData", {
        keys: { id_Sanzione: id },
        apiLink: globalApi.LOOKUP_STATO_SANZIONI_FILTER,
      });
    };

    const isLoading = ref(false);

    const delSanzione = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "La sanzione verrà eliminata definitivamente.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteSanzione(id).then((res) => {
            if (res.data.statusCode == 200) {
              alertSuccess("Sanzione eliminata correttamente");
              viewEditProcedimento(id_procedimento);
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const delSentenza = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "La sentenza verrà eliminata definitivamente.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteSentenza(id).then((res) => {
            if (res.data.statusCode == 200) {
              alertSuccess("Sentenza eliminata correttamente");
              viewEditProcedimento(id_procedimento);
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    return {
      sanzioni_tipo,
      sanzioni_stato_limited,
      sentenzaSelected,
      sanzioneSelected,
      sentenzaSelectedId,
      format,
      id_procedimento,
      jsonViewEditProcedimento,
      isLoaded,
      viewEditProcedimento,
      getSanzioni,
      delSanzione,
      delSentenza,
      isLoading,
      primogrado,
      sanzioni_cause_estintive,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
});
</script>

<style scoped>
.accordion-body {
  border: 1px solid #f0f2f5;
}
.modal-content {
  width: 100%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}

.sanzioni-list {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.sanzioni-list > .sanzioni-item:nth-child(odd) {
  background-color: #f8f9fa;
}
</style>

<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add_sanzione">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Aggiungi sanzione</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="off">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-show="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row pb-4">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Stato sanzione*</label
                        >
                        <SelectInput
                          :value="id_Stato_Sanzione"
                          :options="sanzioni_stato"
                          name="id_Stato_Sanzione"
                          placeholder="-"
                          @changeSelect="setStatoSanzione($event)"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Patteggiamento</label
                        >
                        <SelectInput
                          :value="id_Tipo_Patteggiamento"
                          :options="sanzioni_tipo_patteggiamento"
                          name="id_Tipo_Patteggiamento"
                          placeholder="-"
                          @changeSelect="setTipoPattegiamento($event)"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Sanzione*</label>
                        <SelectInput
                          :value="id_Tipo_Sanzione"
                          :options="sanzioni_tipo"
                          name="id_Tipo_Sanzione"
                          placeholder="-"
                          @changeSelect="setTipoSanzione($event)"
                        />
                      </div>
                    </div>
                    <div class="row pb-4">
                      <div class="col-md-6">
                        <label class="fw-bold text-gray-600">Società*</label>
                        <div class="dp__input_wrap">
                          <input
                            placeholder=""
                            name="nome_societa"
                            type="text"
                            class="form-control"
                            @input="
                              focusSocieta = true;
                              getSocieta($event.target.value);
                              nome_societa === ''
                                ? (id_Societa = null)
                                : (id_Societa = 0);
                            "
                            v-model="nome_societa"
                            autocomplete="off"
                          />
                          <Field
                            name="id_Societa"
                            type="hidden"
                            v-model="id_Societa"
                            class="form-control"
                          />
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            class="dp__icon dp__clear_icon dp__input_icons"
                            @click="
                              id_Societa = null;
                              nome_societa = null;
                            "
                          >
                            <path
                              d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                            ></path>
                            <path
                              d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                            ></path>
                          </svg>
                        </div>
                        <ListSocieta
                          v-if="focusSocieta"
                          :list="societa"
                          :focusOnInput="focusSocieta"
                          @hideList="resetSocieta"
                          @selectedSocieta="
                            nome_societa = $event.name;
                            id_Societa = $event.value;
                            focusSocieta = false;
                            resetSocieta();
                          "
                        ></ListSocieta>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="id_Societa"
                        />
                      </div>
                      <div class="col-md-6">
                        <label class="fw-bold text-gray-600">Tesserato</label>
                        <div class="dp__input_wrap">
                          <input
                            placeholder=""
                            name="nome_persona"
                            type="text"
                            class="form-control"
                            @input="
                              getTesserati($event.target.value);
                              nome_persona === ''
                                ? (id_Atleta = null)
                                : (id_Atleta = 0);
                            "
                            v-model="nome_persona"
                            @focus="focusTesserato = true"
                            autocomplete="off"
                            :disabled="id_Tipo_Sanzione == 14 || !id_Societa"
                          />
                          <Field
                            name="id_Atleta"
                            type="hidden"
                            v-model="id_Atleta"
                            class="form-control"
                          />
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            class="dp__icon dp__clear_icon dp__input_icons"
                            @click="
                              id_Atleta = null;
                              nome_persona = null;
                            "
                          >
                            <path
                              d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                            ></path>
                            <path
                              d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                            ></path>
                          </svg>
                        </div>
                        <ListTesserati
                          :focusOnInput="focusTesserato"
                          :list="tesseratiList"
                          @hideList="
                            resetTesseratiList();
                            focusTesserato = false;
                          "
                          @selectedTesserato="
                            nome_persona = $event.name;
                            id_Atleta = $event.value;
                            focusTesserato = false;
                            resetTesseratiList();
                          "
                        ></ListTesserati>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="id_Atleta"
                        />
                      </div>
                    </div>
                    <div class="row pb-4" v-show="id_Tipo_Sanzione == 15">
                      <div class="col-sm-4 filter">
                        <label class="fw-bold text-gray-600">Ammenda*</label>
                        <Field
                          v-model="ammenda"
                          type="text"
                          class="form-control"
                          name="ammenda"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="ammenda"
                        />
                      </div>
                      <div class="col-sm-4 filter">
                        <label class="fw-bold text-gray-600"
                          >Data decorrenza*</label
                        >
                        <Datepicker
                          v-model="data_Decorrenza"
                          @update:modelValue="
                            $event
                              ? (data_Decorrenza = fromUTCtoISODate($event))
                              : ''
                          "
                          placeholder=""
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          autoApply
                        />
                        <Field
                          name="data_Decorrenza"
                          type="hidden"
                          v-model="data_Decorrenza"
                          class="form-control"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_Decorrenza"
                        />
                      </div>
                    </div>
                    <div
                      class="row pb-4"
                      v-show="
                        id_Tipo_Sanzione == 6 ||
                        id_Tipo_Sanzione == 2 ||
                        id_Tipo_Sanzione == 3 ||
                        id_Tipo_Sanzione == 4 ||
                        id_Tipo_Sanzione == 1 ||
                        id_Tipo_Sanzione == 14 ||
                        id_Tipo_Sanzione == 12
                      "
                    >
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Giorni*</label>
                        <Field
                          type="text"
                          class="form-control"
                          name="num_Giorni"
                          v-model="num_Giorni"
                          @input="dateFromNumGiorni(data_inizio)"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="num_Giorni"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Data Inizio*</label
                        >
                        <Datepicker
                          v-model="data_Da"
                          @update:modelValue="
                            $event ? (data_Da = fromUTCtoISODate($event)) : '';
                            $event ? dateFromNumGiorni($event) : '';
                          "
                          @cleared="data_A = null"
                          placeholder=""
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          autoApply
                        />
                        <Field
                          name="data_Da"
                          type="hidden"
                          v-model="data_Da"
                          class="form-control"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_Da"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Data Fine*</label>
                        <Datepicker
                          v-model="data_A"
                          placeholder=""
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          autoApply
                          readonly
                        />
                        <Field
                          name="data_A"
                          type="hidden"
                          v-model="data_A"
                          class="form-control"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_A"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalAddSanz"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import { fromUTCtoISODate } from "@/composables/formatDate";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";
import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";
import { addSanzione } from "@/requests/sanzioniDisciplinariRequests";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "add-sanzione-modal",
  emits: ["viewProcedimento"],
  components: {
    Loading,
    Form,
    SelectInput,
    Datepicker,
    Field,
    ErrorMessage,
    ListTesserati,
    ListSocieta,
  },
  props: {
    id_procedimento: {
      type: Number,
      required: true,
    },
    id_sentenza: {
      type: Number,
      required: true,
    },
    stagione: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const sanzioni_stato = computed(() =>
      store.getters.getStateFromName("sanzioni_stato")
    );
    const sanzioni_tipo_patteggiamento = computed(() =>
      store.getters.getStateFromName("sanzioni_tipo_patteggiamento")
    );
    const sanzioni_tipo = computed(() =>
      store.getters.getStateFromName("sanzioni_tipo")
    );
    const keys = ref("sst|stp|stip");
    if (
      !sanzioni_stato.value ||
      !sanzioni_tipo_patteggiamento.value ||
      !sanzioni_tipo.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const nome_societa = ref("");
    const id_Societa = ref(null);
    const nome_persona = ref("");
    const id_Atleta = ref(null);

    /* const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };

    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    }; */

    const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaProv", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const getTesserati = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      }
      if (string.length < 3) return;
      store.dispatch("getPersoneSanzioni", {
        string,
        id_societa: id_Societa.value,
        stagione: props.stagione,
      });
    };

    const resetTesseratiList = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const ammenda = ref(null);
    const data_Decorrenza = ref(null);
    const data_A = ref(null);
    const data_Da = ref(fromUTCtoISODate(new Date()));
    const num_Giorni = ref(null);
    const id_Tipo_Patteggiamento = ref(null);
    const id_Stato_Sanzione = ref(1);
    const id_Tipo_Sanzione = ref(null);

    const setTipoPattegiamento = (event) => {
      id_Tipo_Patteggiamento.value = event;
    };
    const setTipoSanzione = (event) => {
      id_Tipo_Sanzione.value = event;
      data_Da.value = null;
      data_A.value = null;
      num_Giorni.value = null;
      ammenda.value = null;
      data_Decorrenza.value = null;
    };
    const setStatoSanzione = (event) => {
      id_Stato_Sanzione.value = event;
    };

    const dateFromNumGiorni = () => {
      if (data_Da.value) {
        const dataInizio = ref(new Date(data_Da.value));
        const dataFine = ref(new Date());

        dataFine.value.setTime(
          dataInizio.value.getTime() + num_Giorni.value * 24 * 60 * 60 * 1000
        );

        data_A.value = fromUTCtoISODate(dataFine.value);
      }
    };

    const alertCampiObb = ref(false);

    const schema = yup.object().shape({
      id_Tipo_Sanzione: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      id_Stato_Sanzione: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      id_Societa: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      data_Decorrenza: yup.string().when("id_Tipo_Sanzione", {
        is: (id_Tipo_Sanzione) => id_Tipo_Sanzione == 15,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      data_Da: yup.string().when("id_Tipo_Sanzione", {
        is: (id_Tipo_Sanzione) =>
          id_Tipo_Sanzione == 6 ||
          id_Tipo_Sanzione == 2 ||
          id_Tipo_Sanzione == 3 ||
          id_Tipo_Sanzione == 4 ||
          id_Tipo_Sanzione == 1 ||
          id_Tipo_Sanzione == 14,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      data_A: yup.string().when("id_Tipo_Sanzione", {
        is: (id_Tipo_Sanzione) =>
          id_Tipo_Sanzione == 6 ||
          id_Tipo_Sanzione == 2 ||
          id_Tipo_Sanzione == 3 ||
          id_Tipo_Sanzione == 4 ||
          id_Tipo_Sanzione == 1 ||
          id_Tipo_Sanzione == 14,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      num_Giorni: yup.number().when("id_Tipo_Sanzione", {
        is: (id_Tipo_Sanzione) =>
          id_Tipo_Sanzione == 6 ||
          id_Tipo_Sanzione == 2 ||
          id_Tipo_Sanzione == 3 ||
          id_Tipo_Sanzione == 4 ||
          id_Tipo_Sanzione == 1 ||
          id_Tipo_Sanzione == 14,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("Obbligatorio")
          .nullable(),
        otherwise: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      ammenda: yup.number().when("id_Tipo_Sanzione", {
        is: (id_Tipo_Sanzione) => id_Tipo_Sanzione == 15,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("Obbligatorio")
          .nullable(),
        otherwise: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      alertCampiObb.value = false;
      const responseAdd = ref({});
      let messaggioResponse;
      responseAdd.value = addSanzione(values, props.id_sentenza);
      messaggioResponse = "La sanzione è stata aggiunta con successo";
      responseAdd.value.then((value) => {
        if (value.status == 200) {
          Swal.fire({
            html: messaggioResponse,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            },
          });
          document.getElementById("closeModalAddSanz").click();
          emit("viewProcedimento", props.id_procedimento);
          resetField();
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      nome_societa.value = null;
      id_Societa.value = null;
      nome_persona.value = null;
      id_Atleta.value = null;
      ammenda.value = null;
      data_Decorrenza.value = null;
      data_A.value = null;
      data_Da.value = fromUTCtoISODate(new Date());
      num_Giorni.value = null;
      id_Tipo_Patteggiamento.value = null;
      id_Stato_Sanzione.value = 1;
      id_Tipo_Sanzione.value = null;
    };

    return {
      sanzioni_tipo_patteggiamento,
      sanzioni_stato,
      sanzioni_tipo,
      getSocieta,
      resetSocieta,
      societa: computed(() => store.getters.societaNomeCodAff),
      tesseratiList: computed(() => store.getters.personeSocieta),
      getTesserati,
      resetTesseratiList,
      nome_societa,
      id_Societa,
      nome_persona,
      id_Atleta,
      ammenda,
      data_Decorrenza,
      data_A,
      data_Da,
      num_Giorni,
      id_Tipo_Patteggiamento,
      id_Stato_Sanzione,
      id_Tipo_Sanzione,
      setTipoPattegiamento,
      setTipoSanzione,
      setStatoSanzione,
      alertCampiObb,
      schema,
      onInvalidSubmit,
      onSubmit,
      resetField,
      fromUTCtoISODate,
      format,
      dateFromNumGiorni,
      disableButton,
      isLoading,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
